import React from "react";

import { graphql } from "gatsby";
import Layout from "../components/layout";
import { AllFilmQueryProps } from "src/types";
import FilmCard from "../components/filmCard";

const ReviewsPage: React.FC<AllFilmQueryProps> = ({ data }) => {
  return data ? (
    <Layout>
      <h1>Latest Reviews:</h1>
      <div
        style={{
          display: "flex",
          flexWrap: "wrap"
        }}
      >
        {data.allMarkdownRemark.edges
          .sort(
            ({ node: a }: any, { node: b }: any) =>
              new Date(b.frontmatter.date).getTime() -
              new Date(a.frontmatter.date).getTime()
          )
          .map((film: any) => (
            <div style={{ marginRight: 8, marginBottom: 12 }}>
              <FilmCard size="m" film={film.node} />
            </div>
          ))}
      </div>
    </Layout>
  ) : null;
};

export const pageQuery = graphql`
  query ReviewsPageQuery {
    allMarkdownRemark(filter: { fileAbsolutePath:{regex:"/films/.*\\.md$/" } }, sort: { fields:frontmatter___date, order: DESC }) {
      edges {
        node {
          id
          frontmatter {
            path
            title
            date
            poster {
              childImageSharp {
                fluid(maxWidth: 800) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default ReviewsPage;
